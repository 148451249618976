@import '../../../../core/styles/mixins';

:global(.iframe) .scroll {
  height: 100%;
}

.item-page {
  overflow: scroll;
  width: 100%;
  font-family: 'Open Sans';
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  color: #121212;
  margin-bottom: 50px;

  &__image {
    margin: 16px;
    width: auto;
    height: 358px;
    border-radius: 12px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__isNative {
      margin: 29px 16px 16px;
    }
  }

  &__form {
    padding-bottom: 128px;

    &__label {
      padding: 18px 16px 8px 16px;
      color: #1d1b20;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &__card {
      margin: 8px 16px 6px 16px;
      padding: 10px;
      border-radius: 12px;
      background: linear-gradient(0deg, #fff 0%, #fff 100%), #027bfd;
      border: none;
      resize: none;

      &__input {
        color: #1d1b20;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &__kcal {
      margin: 16px;
      width: 448px;
    }

    &__footer {
      height: 128px;
      background: #fff;
      box-shadow: 2px 0 4px 2px rgba(0, 0, 0, 0.1);
      bottom: 0;
      position: fixed;
      width: 100%;
      display: flex;
      flex-direction: column;
      border: none;
      margin-top: auto;

      &__quantity {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__remove {
        width: fit-content;
        padding: 0 0 16px 0;
        text-align: center;
        cursor: pointer;
        pointer-events: initial;
        color: #f00;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }

  &__additional-title {
    margin-top: 15px;
  }
}

.scroll {
  overflow: auto;
  margin-top: 56px;
  height: calc(100% - 56px);
}

.comment {
  margin-top: 0;
}

.flex {
  flex: 1;
}

@include bp(lg) {
  .item-page {
    height: 100%;

    &__image {
      width: 448px;
      height: 448px;
    }

    &__form {
      padding-bottom: 16px;

      &__card {
        width: 448px;
      }

      &__footer {
        position: absolute;
        bottom: 0;

        &__custom-button {
          position: absolute;
          bottom: 16px;
          width: 448px;
        }
      }
    }
  }

  .scroll {
    margin-top: 56px;
    width: 476px;
    overflow-x: hidden;
  }
}
