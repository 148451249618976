.allergens-wrap {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0;
  line-height: 20px;
  flex-wrap: wrap;
  cursor: pointer;

  &__item {
    height: 20px;
    width: 20px;
    margin-right: 12px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
