@import '../../../../core/styles/breakpoints';

.options-content {
  position: relative;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  padding-top: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &__header {
    margin-bottom: 16px;
    padding: 0 16px;
  }
  &__title {
    letter-spacing: 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #1d1b20;
  }
  &__welcome {
    font-size: 14px;
    margin-bottom: 4px;
  }
  &__list {
    padding: 0 16px 24px;
    margin: 0;
    overflow: auto;
  }
  &__list-item {
    list-style: none;
    border: 1px solid #777777;
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    font-family: 'Open Sans';
    font-size: 16px;
    padding: 13px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    & + & {
      margin-top: 16px;
    }
  }
  &__icon {
    transform: rotate(-90deg);
    height: 9.48px;
    width: 16px;
  }
}
