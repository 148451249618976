@import '../../../../core/styles/breakpoints';

.modal {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: $lg-breakpoint) and (orientation: landscape) {
    position: relative;
    max-width: 250px;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
  }
}

.modal-title {
  @media (min-width: $lg-breakpoint) and (orientation: landscape) {
    width: 100%;
    text-align: center;
  }
}

.modal-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 13px;
  height: 13px;

  @media (min-width: $lg-breakpoint) and (orientation: landscape) {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    width: initial;
    height: initial;

    svg {
      height: 10px;
      width: 10px;
    }
  }
}

.modal-container {
  display: flex;
  width: 100%;
}
