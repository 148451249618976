@import '../../../../core/styles/mixins';

.payments-method-box {
  &__container {
    border-radius: 4px;
    position: relative;
    width: 100%;
    padding: 16px;
    outline: none;
    background: transparent;
    border-bottom: 0.65px dashed #e3e3e3;

    &__title {
      color: #1d1b20;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 16px;
    }

    &__box {
      border: 1px solid #777777;
      background-color: #f1f1f1;
      position: relative;
      width: 100%;
      height: 48px;
      padding: 14px 7px 0 7px;
      outline: 0;
      resize: none;
      border-radius: 4px;
      color: #1d1b20;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      &__change {
        position: absolute;
        right: 16px;
        top: 16px;
        font-family: 'Open Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &__disable {
        color: #777777;
      }
    }
  }
}

@include bp(lg) {
  .payments-method-box {
  }
}
