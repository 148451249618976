@import '../../../../core/styles/mixins';

.addons-group {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 18px 16px;
  margin: 0 16px 16px 16px;
  border: 1px solid transparent;
  border-radius: 12px;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #027bfd;

  &__title-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 16px 8px;
    color: #1d1b20;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: default;
  }

  &__checkbox-wrap {
    display: flex;
    flex-direction: column;

    & > div:first-child {
      margin-top: 0;
    }

    &__item {
      display: flex;
      flex-direction: row;
      margin-top: 14px;
      position: relative;

      &:first-child {
        margin-top: initial;
      }

      &__checkbox {
        margin: 0 5px;

        input {
          opacity: 0;
          cursor: pointer;
          margin: 0;
        }

        input:checked ~ &_custom {
          background-color: #ffffff;
          border-radius: 6px;
          transform: rotate(0deg) scale(1);
        }

        input:checked ~ &_custom::after {
          transform: rotate(45deg) scale(1);
          opacity: 1;
          left: 4px;
          top: 0;
          width: 4px;
          height: 10px;
          border: solid #2672cd;
          border-width: 0 2px 2px 0;
          background-color: transparent;
          border-radius: 0;
        }

        &_custom {
          position: absolute;
          left: 3px;
          height: 16px;
          width: 16px;
          background-color: transparent;
          border-radius: 5px;
          border: 1px solid #2672cd;
          pointer-events: none;
        }

        &_custom::after {
          position: absolute;
          content: '';
          left: 8px;
          top: 8px;
          border-radius: 5px;
          border: solid #2672cd;
          border-width: 0 3px 3px 0;
          transform: rotate(0deg) scale(0);
          opacity: 1;
        }
      }

      &__title {
        line-height: 17px;
      }

      &__price {
        font-family: 'Open Sans';
        font-weight: bold;
        line-height: 17px;
        margin-left: auto;
        color: #0f0f0f;
      }
    }
  }
}

.error {
  border: 1px solid #bb0e2d;
}

.select-label {
  white-space: nowrap;
  color: #1d1b20;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.row {
  margin-top: 14px;

  &:first-of-type {
    margin-top: initial;
  }

  .kcal {
    color: rgba(120, 120, 120, 1);
    font-size: 10px;
    font-weight: 400;
    font-style: normal;
    padding-left: 28px;
    cursor: default;
  }
}

.rowAllergens {
  margin-left: 27px;

  & > div {
    cursor: pointer;
  }
}

html[dir='rtl'] {
  .addons-group {
    padding-left: 18px;
    padding-right: 6px;
  }

  .select-label {
    direction: ltr;
  }
}

@include bp(lg) {
  .addons-group {
    width: 448px;
  }
}
