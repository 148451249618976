.root {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 10;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #787878;
  height: 64px;
  font-size: 20px;
  line-height: 28px;
  color: #fff;
  font-weight: 700;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.email-title {
  color: #777;
  margin-bottom: 24px;
}

.title {
  font-size: 57px;
  line-height: 64px;
  font-weight: 700;
  color: #1d1b20;
  margin: 0 24px;
}

.tap-image {
  width: 243px;
  margin: 48px 0;
}

.subtitle {
  font-weight: 400;
  line-height: 32px;
  color: #1d1b20;
}

.loader-container {
  width: 300px;
  height: 200px;
}

.caption-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
}

.caption {
  font-size: 20px;
  line-height: 28px;
  margin: 0;
}

.caption-issue {
  @extend .caption;
  margin-bottom: 100px;
}

.button-action {
  position: static;
  max-width: 616px;
  margin: 0 0 16px 0;
  color: #fff;
}

.button {
  @extend .button-action;
  margin: 0;
  color: #191c1d;
  background-color: transparent !important;
  box-shadow: none;
  border: 1px solid #777777;
}
