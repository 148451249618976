@import '../../core/styles/variables.module';

.root {
  font-size: 16px;
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 32px;
  text-align: center;
  height: 32px;
  &--success {
    background-color: $success;
    color: #fff;
    border: 1px solid #fff;
  }
}
