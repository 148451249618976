.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 12px;
  height: 48px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-transform: none;
  border: none;
  color: white;
  cursor: pointer;
}
