@import '../../../../core/styles/mixins';

.kcal-adult-label-container {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  display: flex;
  padding: 12px;
  border-radius: 12px;
  opacity: 0.9;
  backdrop-filter: blur(2px);
  margin: 16px;

  .icon {
    width: 24px;
    height: 24px;

    .custom-icon {
      color: #777777;
    }
  }

  .text {
    padding-left: 12px;
    color: #1d1b20;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    cursor: default;
  }
}

@include bp(lg) {
  .kcal-adult-label-container {
    margin: 0 0 32px 0;
    width: 358px;
  }
}
