.payment-methods-page {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  z-index: 6;
}

.payment-methods {
  display: flex;
  flex-direction: column;
  flex: 1;

  .noCardsTitle,
  .cardsTitle {
    font-size: 16px;
    text-align: center;
    margin: 20px 0;
    font-weight: 600;
  }

  .cardsTitle {
    color: #1d1b20;
  }

  .noCardsTitle {
    color: #777777;
  }

  .cardManagementInfo {
    margin: 0 35px;
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
  }
}
