@import '../../core/styles/breakpoints';

.picker-container {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  width: 100%;

  &,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
}

.picker-inner {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 0 10px;
  font-size: 1.2em;
  -webkit-mask-image: linear-gradient(
    to top,
    transparent,
    transparent 5%,
    white 20%,
    white 80%,
    transparent 95%,
    transparent
  );
  -webkit-mask-repeat: no-repeat;
}

.picker-highlight {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  pointer-events: none;

  &:before,
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: auto;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    transform: scaleY(0.5);
  }

  &:before {
    top: 0;
    bottom: auto;
  }

  &:after {
    bottom: 0;
    top: auto;
  }
}

@media (max-width: $lg-breakpoint) {
  .picker-inner {
    -webkit-mask-image: linear-gradient(
      to top,
      transparent 0%,
      white 70%,
      transparent 100%
    );
  }
  .picker-highlight {
    background-color: #f1f1f1;
    z-index: -1;

    &:before,
    &:after {
      content: none;
    }
  }
}
