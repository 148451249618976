@import '../../../../core/styles/breakpoints';
@import '../../../../core/styles/mixins';

.category {
  padding: 16px;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &__isNative {
    padding: 29px 16px 16px;
  }

  &__name {
    text-transform: uppercase;
  }

  &__subcategory {
    color: #1d1b20;
    font-weight: 400;
    padding-top: 0;
  }

  &__products {
    background: transparent;
    border-radius: 12px;
    margin: 0 16px;
    cursor: pointer;

    &__item {
      justify-content: space-between;
      padding: 12px 16px;
      background: #fff;
      box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.3);
      border-radius: 12px;
      margin-bottom: 16px;
      position: relative;

      &__info {
        margin-bottom: 16px;
        min-height: 112px;
        flex: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        &__name {
          color: #1d1b20;
          font-family: 'Open Sans';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: calc(100% - 16px);
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }

        &__calories {
          color: #1d1b20;
          font-family: 'Open Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &::before {
            content: '\2022';
            padding: 5px;
          }
        }

        &__desc {
          color: #1d1b20;
          font-family: 'Open Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: calc(100% - 16px);
          padding-top: 4px;
        }
      }

      &__footer {
        display: inline-flex;
        width: 100%;

        &__price {
          color: #1d1b20;
          font-family: 'Open Sans';
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-align: right;
          width: 50%;
        }

        &__outOfStock {
          color: #777;
          text-align: right;
          font-family: 'Open Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          width: 50%;
          float: right;
        }
      }

      &__image {
        float: right;
        height: 96px;
        width: 96px;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      &__image-opacity {
        filter: opacity(0.6);
      }

      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}

.itemWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 8px;
}

@include bp(lg) {
  .category {
    padding: 16px 16px 16px 0;
    font-size: 20px;
    font-weight: 600;

    &__subcategory {
      font-weight: 400;
      padding-top: 0;
    }

    &__products {
      &__item {
        margin-right: 24px;
        margin-bottom: 24px;
        cursor: pointer;

        &__info {
          min-height: 96px;
        }
      }
    }

    &__footer {
      &__outOfStock {
        font-size: 14px;
      }
    }
  }
}

.outOfStock {
  opacity: 0.4;
}

.unclickable {
  cursor: default;
}

.badge {
  &--with-image {
    position: absolute;
    top: 16px;
    right: 20px;
  }
  &--no-image {
    float: right;
    margin-left: 8px;
  }
}

html[dir='rtl'] {
  .category {
    direction: rtl;
  }

  .itemWrapper {
    direction: rtl;
  }
}
