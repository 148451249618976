.root {
  background-color: #f1f1f1;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.column {
  height: 100%;
  width: 260px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  flex-direction: column;

  &-caption {
    margin-top: 8px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  }
}
