@import './breakpoints';

@mixin bp($size) {
  @if $size == xl {
    @media only screen and (min-width: $xl-breakpoint) {
      @content;
    }
  }

  @if $size == lg {
    @media only screen and (min-width: $lg-breakpoint) {
      @content;
    }
  }

  @if $size == md {
    @media only screen and (min-width: $md-breakpoint) {
      @content;
    }
  }

  @if $size == sm {
    @media only screen and (min-width: $sm-breakpoint) {
      @content;
    }
  }

  @if $size == xs {
    @media only screen and (min-width: $xs-breakpoint) {
      @content;
    }
  }
}
