@import '../../../../core/styles/mixins';

:global(.iframe) .location-content {
  height: 100%;
}

.restaurant {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  background: #fff;
  width: 100%;
}

.container {
  flex: 1;
  padding: 12px 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Open Sans';
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  height: 100%;

  &__container {
    height: 100%;
    margin-bottom: 8px;
  }
}

.title {
  font-size: 16px;
  font-family: 'Bold';
  font-weight: 700;
  &--notAvailable {
    color: #777;
  }
}

.description {
  line-height: 19px;
  font-size: 14px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 12px;
}

.imageContainer {
  height: 160px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imageGray {
  opacity: 0.5;
}

.moreInfo {
  color: #027bfd;
  font-weight: 600;
  font-family: 'Open Sans Semibold';
  white-space: nowrap;
}

html[dir='rtl'] {
  .moreInfo {
    left: 20px;
    right: initial;
  }
}

@include bp(lg) {
  .restaurant {
    display: flex;
    width: calc((100% - 72px) / 3);
    margin: 12px;
  }
  .image {
    top: 0;
    left: 0;
    transform: none;
  }
}

@include bp(xl) {
  .restaurant {
    width: calc((100% - 96px) / 4);
  }
}
