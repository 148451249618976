.custom-header-container {
  margin: 5px 10px;
  display: flex;
  justify-content: center;
}

.arrow-component-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__disabled {
    visibility: hidden;
    pointer-events: none;
  }
}
