@import '../../../../../core/styles/mixins';

:global {
  #mycheck-wallet-v2 {
    margin: 16px 0 8px;

    & > div {
      height: initial;

      & > div {
        margin-bottom: initial;

        & > div {
          margin-bottom: initial;

          & > div > div > div > div {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }
      }
    }

    [class*='radioContainer'] {
      display: none;
    }

    [class*='CardAdd_iframe'] {
      margin-bottom: initial;
    }
  }
}

:global {
  #wallet-overlay-paper {
    height: auto;
    max-height: unset !important;
    min-height: 300px;
  }
}

.submit-order-button {
  position: initial;
  margin-bottom: 15px;
}

.wallet {
  padding: 23px 12px 15px;

  div[class*='AppLoader_root'] {
    height: 450px;
  }

  div[class*='AuthFail_container'] {
    height: 450px;

    img {
      max-width: 60%;
    }
  }
}

@include bp(lg) {
  .wallet {
    padding: 23px 12px 0 12px;
  }
}
