@import '../../../../core/styles/breakpoints';

.home-page {
  height: 100vh;
  width: 100%;
  overflow: hidden;

  &__side-bar-footer {
    background-color: #ffffff !important;
  }
}
