.overlay {
  background: rgba(0, 0, 0, 0.31);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
}

.body {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0;
}
