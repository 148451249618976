.root {
  display: block;
  font-family: 'Open Sans';
  font-size: 20px;
  line-height: 28px;
  padding: 20px 20px 58px;
}

.title {
  font-weight: 700;
  color: #bb0e2d;
  margin-top: 18px;
}

.header-title {
  color: #1d1b20;
}

.container {
  padding: 16px 0;
}
