.button {
  width: 100%;
  height: 48px;
  border: none;
  color: #ffffff;
  cursor: pointer;

  &[disabled] {
    background-color: rgba(239, 239, 239, 1);
    border-color: rgba(118, 118, 118, 0.3) !important;
  }
}
