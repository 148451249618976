.form {
  height: 48px;
  border-radius: 4px;
  position: relative;
  width: 100%;
  margin-top: 24px;
  outline: none;
  background: transparent;
  box-sizing: border-box;

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px #ffffff inset !important;
  }

  &.animation {
    transition: margin-bottom 750ms ease-in-out;
  }

  .form-label {
    position: absolute;
    left: 8px;
    top: 16px;
    transition:
      transform 150ms ease-out,
      font-size 150ms ease-out;
    z-index: 1;
    pointer-events: none;
    color: #777777;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &.focused .form-label {
    padding: 0 2px;
    transform: translateY(-26px);
    background-color: #ffffff;
    font-size: 14px;
  }

  &.focused .with-icon {
    transform: translate(-4px, -26px);
  }

  .form-input {
    border: 1px solid #777777;
    background-color: #ffffff;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 7px;
    outline: 0;
    resize: none;
    border-radius: 4px;
    color: #1d1b20;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    &::placeholder {
      color: transparent;
    }

    &.animation {
      transition: border 750ms ease-in-out;
    }
  }

  textarea.form-input {
    padding: 10px 7px;
  }

  .formErrorText {
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
    color: #bb0e2d;
  }

  &__optionalLabel {
    font-family: Open Sans;
    position: absolute;
    top: -18px;
    right: 2px;
    color: #838383;
    font-size: 12px;
  }
}

html[dir='rtl'] {
  .form {
    .form-label {
      left: initial;
      right: 7px;
    }

    &__optionalLabel {
      right: initial;
      left: 2px;
    }
  }
}
