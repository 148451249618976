.time {
  line-height: 1.5;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 12px;

  span {
    color: #ffffff;
    padding: 2px 8px;
    font-family: 'Open Sans Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    min-height: 100%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    white-space: nowrap;
  }
}

.image {
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 12px;
  left: 0;
}

html[dir='rtl'] {
  .image {
    right: initial;
    left: 20px;
  }
}
