.root {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.content {
  position: relative;
  z-index: 5;
  margin: 34px;
  max-height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
}

.body {
  overflow-y: auto;
  background: #fff;
}

.header {
  padding: 20px 64px;
  position: relative;
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  min-height: 64px;
  box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.3);
}

.closeButton {
  background: transparent;
  border: none;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  path {
    fill: #fff;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.31);
  backdrop-filter: blur(2px);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4;
}
