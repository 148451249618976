.next-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  border: none;
  color: #ffffff;
  padding: 8px;
  margin-right: 8px;
  margin-top: 8px;
  border-radius: 4px;

  &_disabled {
    background-color: #787878;

    svg {
      height: 12px;
      width: 12px;
    }
  }
}

html[dir='rtl'] {
  .next-button {
    left: 0;
    right: initial;
    margin-left: 8px;
    margin-right: initial;
    transform: scaleX(-1);
  }
}
