.header-actions {
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  height: 64px;
  width: 100%;
  display: flex;
  z-index: 1;
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 24px;
}
