@import '../../core/styles/mixins';

.footer {
  width: 100%;
  height: 64px;
  text-align: center;
  border-top: 1px solid #cde5ff;
  padding: 10px 16px;

  &__link,
  &__divider {
    color: #777777;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: none;
    display: inline-block;
    cursor: pointer;
  }
  &__link {
    padding-right: 8px;
    padding-left: 8px;
  }

  &__divider {
    padding-right: 0;
    padding-left: 0;

    &:last-child {
      display: none;
    }
  }

  &.mobile-order-button-placeholder {
    height: 100px;
  }
}

@include bp(lg) {
  .footer {
    display: flex;
    padding: 22px 32px 22px 48px;
    height: 68px;

    &__link {
      width: auto;
      height: 24px;
    }
  }
}
