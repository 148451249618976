.sidebar {
  display: flex;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    height: calc(100% - 56px);
    background-color: #ffffff;
    top: 56px;
    right: 0;
    left: 0;
    width: 320px;
    z-index: 5;
    transition: left 0.5s;
    overflow: hidden;
    box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.05);
    opacity: 1;

    &__copyright {
      margin-bottom: 14px;
      font-size: 12px;
      display: flex;
      justify-content: center;
    }

    &__full-screen {
      top: 0;
      height: 100%;
    }
  }

  &__overlay {
    background: rgba(0, 0, 0, 0.31);
    position: fixed;
    top: 56px;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 2;

    &__full-screen {
      top: 0;
    }
  }
}

html[dir='rtl'] {
  .sidebar {
    &__container {
      right: 0;
      transition: right 0.5s;
    }

    &__container_hide {
      left: initial;
      right: -242px;
    }
  }
}
