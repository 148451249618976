.history-page {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  z-index: 6;
  background-color: #fff;

  &__no-orders {
    text-align: center;

    p {
      color: grey;
      font-weight: 600;
      font-size: 16px;
      margin-top: 20px;
    }
  }
}
