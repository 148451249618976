.lang-list {
  &__item {
    display: flex;
    align-items: flex-start;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    padding: 14.5px 16px;
    cursor: pointer;
    color: #1d1b20;

    &__selected {
      background-color: #f1f1f1;
    }

    &__flag {
      margin: 3px 16px 0 0;
      height: auto;
      box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
    }

    &:hover:not(&__selected) {
      background-color: #fcfcfc;
    }
  }
}
