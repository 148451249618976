.my-order-list {
  overflow: auto;
  display: flex;
  flex-direction: column;

  &__item {
    padding: 15px 40px 0;

    &__main {
      display: flex;
      justify-content: space-between;
    }

    &__modifier {
      display: flex;
      justify-content: space-between;
      margin: 5px 5px 0 15px;
    }
  }
}
