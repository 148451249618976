.paymentMethodItem {
  display: flex;
  padding: 0 15px;
  border: 1px solid #777777;
  border-radius: 10px;
  margin: 0 15px 15px 15px;
  height: 48px;

  &__wrapper {
    img {
      max-width: 35px;
      max-height: 24px;
      border: 1px solid lightgray;
      border-radius: 5px;
    }

    display: flex;
    align-items: center;
    gap: 15px;
  }
}
