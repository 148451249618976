.modal {
  max-height: 300px;
}

.close {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 10px;

  svg {
    height: 10px;
    width: 10px;
  }
}

.root {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: 25px;
}

.icon {
  max-width: 50px;
  max-height: 50px;
  margin-bottom: 20px;
}

.text {
  text-align: center;
}
