@import '../../core/styles/breakpoints.scss';

.root {
  width: 100%;
  height: 48px;

  .content {
    &__box {
      height: 44px;
      background: #ffffff;
      border: 1px solid #777777;
      border-radius: 12px;

      &__type {
        font-family: 'Open Sans';
        font-size: 16px;
        line-height: 22px;
        padding-left: 16px;
        float: left;
        color: #1d1b20;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 300px;
        display: flex;
        align-items: center;
        height: 100%;
      }

      &__icon {
        float: right;
        padding: 16px;

        &__custom {
          svg {
            height: 16px;
            width: 16px;
          }
          color: #3f7bab;
          padding-right: 12px;
          position: relative;
          top: 1px;
          display: inline-block;
        }
        &__arrow {
          svg {
            height: 10px;
            width: 16px;
          }

          color: #3f7bab;
        }
      }
    }
  }
}
@media (max-width: $lg-breakpoint) {
  .root {
    .content {
      &__box {
        box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
        border: none;
        &__type {
          font-family: 'Open Sans Bold';
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: #1d1b20;
          padding: 13px 0 13px 13px;
          float: left;
          max-width: calc(100% - 55px);
        }
        &__icon {
          padding: 19px;
        }
      }
    }
  }
}
