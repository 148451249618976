.field {
  &__item {
    display: flex;
    flex-direction: row;
    position: relative;
    cursor: pointer;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    color: #1d1b20;
    line-height: normal;
    font-style: normal;

    &__checkbox {
      margin: 0 8px 0 0;
      min-width: 17px;
    }

    &__price {
      margin-left: auto;
    }
  }
}

.checkbox {
  height: 16px;
  width: 16px;
}
