.picker {
  position: absolute;
  width: 346px;
  height: 367px;
  left: 24px;
  top: 10px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.3);
  border-radius: 12px;

  &__header {
    height: 55px;
    border-bottom: 1px solid #c6c6c6;

    &__close-icon {
      position: absolute;
      right: 28px;
      top: 20px;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    &__title {
      font-family: 'Open Sans Bold';
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #1d1b20;
      text-align: center;
      padding: 17px 0 16px 0;
    }
  }

  &__list {
    text-align: center;
    padding-top: 24px;
    overflow: scroll;
    max-height: 312px;

    &__linear {
      -webkit-mask-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) -1.02%,
        #ffffff 50%
      );
      -webkit-mask-repeat: no-repeat;
    }

    &__item {
      width: 298px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #777777;
      border-radius: 20px;
      margin: 0 auto 16px auto;
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #1d1b20;
      padding: 9px 20px 0 25px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:last-child {
        margin: 0 auto;
      }

      &:hover {
        background: #cedff2;
        border: 1px solid #adc9e8;
        border-radius: 20px;
        cursor: pointer;
      }

      &__active {
        background: #adc9e8;
        border: 1px solid #3f7bab;
      }
    }

    &__flex-item {
      display: inline-grid;
      width: 90px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #777777;
      border-radius: 20px;
      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      color: #1d1b20;
      margin: 8px;

      &:hover {
        background: #cedff2;
        border: 1px solid #adc9e8;
        border-radius: 20px;
        cursor: pointer;
      }

      &__active {
        background: #adc9e8;
        border: 1px solid #3f7bab;
      }
    }
  }

  &__footer {
    position: absolute;
    width: 336px;
    height: 59px;
    bottom: 0;
    background: #ffffff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    &__right,
    &__left {
      font-family: 'Open Sans Bold';
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #027bfd;
      padding-top: 14px;
      text-transform: capitalize;
    }

    &__right {
      float: right;
      padding-right: 16px;
    }

    &__left {
      float: left;
      padding-left: 19px;
    }
  }
}
