.my-order-page {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 6;
  background-color: #fff;

  p {
    margin: 0;
  }
}
