@import '../../../../core/styles/mixins';

.root {
  height: 100vh;
  overflow: hidden;
}

.order-page {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 56px;

  &__disclaimer {
    width: calc(100% - 56px);
    bottom: 48px;
    text-align: center;
    margin: 16px 26px;
    padding: 6px 9px 5px 6px;
    pointer-events: none;
    border-radius: 12px;
    background: #f5f5f5;
  }
}

.cancel-order-button {
  margin: 16px 24px 0;
  width: 89%;
}

@include bp(lg) {
  .root {
    height: calc(100vh - 128px);
  }
  .order-page {
    margin: 0 0 24px;

    &__header {
      height: 59px;
      border-bottom: 1px dashed #e3e3e3;
      line-height: 59px;
      padding-left: 24px;
      color: #1d1b20;
      font-size: 20px;
      font-weight: 600;

      &__noItems {
        border-bottom: none;
      }
    }

    &__noItems {
      color: #777;
      font-size: 16px;
      font-weight: 400;
      padding-left: 24px;
      line-height: 59px;
      height: 59px;
    }

    &__custom-button {
      position: static;
    }

    &__custom-button-kiosk {
      bottom: 80px;
    }
  }
}
