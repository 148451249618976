.icon {
  position: absolute;
  margin-left: 12px;
}

.input {
  padding-left: 33px !important;
  margin-top: 0;
  width: 100%;
  font-size: 16px;
}

.list {
  height: 100%;
  overflow: auto;
}

.element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px;
}

.modal {
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0;
  z-index: 1;

  &__title {
    display: flex;
    align-items: center;
    padding: 30px 16px 10px;
    width: 100%;
    z-index: 1;
    box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.05);
  }

  &__icon {
    margin-left: 16px;
  }

  &__container {
    width: 100%;
    height: calc(100% - 70px);
    flex-wrap: wrap;
    flex-direction: column;
    display: flex;
  }

  &__overflow {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}

html[dir='rtl'] {
  .icon {
    margin-right: 8px;
    margin-left: 0;
  }

  .input {
    padding-right: 32px !important;
    padding-left: 0;
  }

  .modal {
    &__icon {
      margin-right: 16px;
      margin-left: 0;
    }
  }
}
