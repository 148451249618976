.wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
  height: 64px;
}

.homeWrapper {
  padding: 10px;
  cursor: pointer;
}
