.overlay {
  width: auto;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
}

.title {
  margin-bottom: 24px;
  padding: 0 24px;
  text-align: center;
}
