html {
  background-color: #f6f6f6;
}

html,
body,
#root {
  width: 100%;
  display: flex;
}

#root > div {
  flex: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/open-sans-v17-latin-regular.eot');
  /* IE9 Compat Modes */
  src:
    local('Open Sans Regular'),
    local('OpenSans-Regular'),
    url('./fonts/open-sans-v17-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/open-sans-v17-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('./fonts/open-sans-v17-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/open-sans-v17-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/open-sans-v17-latin-600.eot');
  /* IE9 Compat Modes */
  src:
    local('Open Sans SemiBold'),
    local('OpenSans-SemiBold'),
    url('./fonts/open-sans-v17-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/open-sans-v17-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/open-sans-v17-latin-600.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/open-sans-v17-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/open-sans-v17-latin-600.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/open-sans-v17-latin-700.eot');
  /* IE9 Compat Modes */
  src:
    local('Open Sans Bold'),
    local('OpenSans-Bold'),
    url('./fonts/open-sans-v17-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/open-sans-v17-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/open-sans-v17-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/open-sans-v17-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/open-sans-v17-latin-700.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

* {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media (min-width: 992px) and (orientation: landscape) {
  *::-webkit-scrollbar {
    width: 4px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #d1d7d7;
  }

  * {
    scrollbar-color: #d1d7d7 transparent;
    scrollbar-width: thin;
  }
}

.ReactModalPortal {
  z-index: 6;
}

.override-bidi-ltr {
  unicode-bidi: bidi-override;
  direction: ltr;
  text-align: right;
}

.override-bidi-rtl {
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: right;
}

.override-bidi-isolate {
  unicode-bidi: isolate;
}

.grecaptcha-badge {
  visibility: hidden;
}
