@import '../../../../../../core/styles/mixins';

.root {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.button-group-container {
  margin-top: 8px;
  & > div {
    align-items: start;
    padding-bottom: 16px;
  }
}
