.root {
  padding: 40px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.item {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
}

.arrow {
  margin: 0 20px;
}

html[dir='rtl'] {
  .icon {
    margin-right: 0;
    margin-left: 10px;
  }

  .arrow {
    transform: scaleX(-1);
  }
}
