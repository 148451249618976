@import '../../../../../core/styles/mixins';

.coupon-modal {
  &__container {
    position: absolute;
    bottom: 0;
    background-color: #ffffff;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: visible;
    border-radius: 16px 16px 0 0;
    height: 311px;

    &__header {
      &__title {
        margin: 28px 0 0 24px;
        letter-spacing: 0;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #1d1b20;
      }

      &__exit {
        position: absolute;
        top: 32px;
        right: 24px;

        img {
          height: 16px;
          width: 16px;
        }
      }
    }

    &__form {
      padding: 80px 0 76px 0;
    }

    &__form-done {
      padding: 56px 0 76px 0;
    }

    &__form-error {
      padding: 40px 0 32px 0;
    }

    &__custom-input {
      margin: 0 16px;
      width: calc(100% - 32px);

      input {
        border-radius: 12px !important;
        text-indent: 43px !important;
      }

      &__icon {
        position: absolute;
        top: 13px;
        left: 16px;
      }
    }

    &__status {
      margin: 0 auto;
      justify-content: center;
      justify-items: center;
      display: grid;

      &__text {
        text-align: center;
        padding: 0 32px;
      }
    }

    &__custom-button {
      border: none;
    }
  }
}

@include bp(lg) {
  .coupon-modal {
    &__container {
      position: unset;
      border-radius: 12px;

      &__header {
        &__title {
          margin: 28px 0 0 16px;
        }

        &__exit {
          position: relative;
          top: -18px;
          left: 326px;
        }
      }

      &__form {
        margin: 58px 0 72px;
        padding: 0;
        height: 48px;
      }

      &__form-done {
        padding: 44px 0 0 0;
      }

      &__form-error {
        padding: 24px 0;
      }

      &__custom-input {
        margin: 0 16px 72px 16px;
      }

      &__status {
        min-height: 50px;
        margin-bottom: 66px;
      }

      &__custom-button {
        position: relative;
        bottom: 0;
        width: 326px;
      }
    }
  }
}
