.root {
  width: calc(100% - 420px);
  padding-left: 5px;
  overflow: auto;
  z-index: 0;
}

.itemWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 358px));
  padding-right: 24px;
}
