@import '../../../../../core/styles/breakpoints';

.experience-modal {
  &__container {
    position: absolute;
    bottom: 0;
    background-color: #ffffff;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: visible;
    border-radius: 16px 16px 0 0;
    height: 359px;

    &__title {
      margin: 25px 0 25px 25px;
      letter-spacing: 0;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #1d1b20;
    }

    &__exit {
      position: absolute;
      top: 24px;
      right: 24px;

      img {
        height: 16px;
        width: 16px;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      justify-content: space-between;

      &__button {
        height: 48px;
        width: 246px;
        border: none;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
      }
    }
  }
}

.primaryButton {
  margin-top: 20px;
  position: absolute;
  bottom: 0;
}

@media (max-width: $lg-breakpoint) {
  .time-modal {
    &__container {
      border-radius: 16px 16px 0 0;
      height: 359px;

      &__exit {
        position: absolute;
        top: 15px;
        right: 15px;

        height: 16px;
        width: 16px;
      }

      &__form {
        padding: 0;
      }
    }
  }
  .primaryButton {
    height: 44px;
    background: #3f7bab;
    box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    margin: 0 16px 16px 16px;
    width: calc(100vw - 32px);
  }
}
