@import '../../core/styles/mixins';

.custom-button {
  position: fixed;
  bottom: 16px;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 0 16px;
  height: 48px;
  color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 13px 0;
  width: calc(100% - 32px);
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: #dddddd !important;
    color: #848484 !important;
  }
}
