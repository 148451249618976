.options-header {
  &__isNative {
    padding-top: 44px;
  }

  position: relative;
  padding: 16px 0 40px;
  z-index: 2;

  &__logoWrapper {
    display: inline-block;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    height: 96px;
    width: 96px;
    padding: 18px;
  }

  &__logo {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__language-picker {
    padding: 12px;
  }

  &__language-picker,
  &__header-profile {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    margin-top: 10px;
    border-radius: 50%;
    background: #fff;
    align-self: self-start;
    width: 40px;
    height: 40px;
    float: right;
  }

  &__header-profile {
    display: flex;
    justify-content: center;
    justify-items: center;
    padding: 12px;
    margin-right: 16px;
  }
}
