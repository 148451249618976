.verification-screen {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__description {
    text-align: center;
    margin: 32px 0 17px;
    font-style: normal;
    font-weight: 400;
    font-family: 'Open Sans';
    line-height: normal;
    font-size: 16px;
  }

  &__input-container {
    width: 100%;
    min-height: 42px;
    min-width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    input::placeholder {
      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 18px;
      color: #111111;
    }

    &__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      border: none;
      color: #ffffff;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__resend {
    position: absolute;
    bottom: 30px;
    text-align: center;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-family: 'Open Sans';
    line-height: normal;
    font-size: 16px;

    &__text {
      text-align: center;
    }
  }
}

.verificationCodeWrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 23px;
  width: 100%;
}
