.root {
  flex-grow: 1;
  overflow: auto;
  margin-top: 56px;
  &--hasIframe {
    margin-top: 0;
  }
  &--withImage {
    .body--lg {
      padding-top: 24px;
    }

    &.body--lg {
      padding-top: 24px;
    }
  }

  &--lg {
    margin-top: 0;

    .image {
      height: 250px;
    }
  }
}

.body {
  padding: 24px;
  font-size: 14px;
  font-family: 'regular';
}

.body--lg {
  composes: body;
  padding-right: 32px;
  padding-left: 32px;
  padding-top: 32px;
}

.title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.description {
  margin-bottom: 10px;
  line-height: 1.42;
  font-size: 16px;
}

.item {
  border-top: 1px solid #f1f1f1;
  padding: 15px 0 14px;
  display: flex;

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }

  &__label {
    color: #787878;
    font-size: 10px;
  }

  &__value a {
    text-decoration: none;
    color: #000;
  }
}

.contacts {
  border-bottom: 1px solid #f1f1f1;
}

.hours {
  margin-top: 24px;
  text-align: right;

  &__title {
    color: #1d1b20;
    margin-bottom: 8px;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
  }

  &__experience {
    color: #787878;
    font-size: 10px;
  }

  &__body {
    margin-top: 6px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 0;
    border-bottom: 1px solid #f1f1f1;

    &:last-child {
      border-bottom: none;
    }

    &__day {
      font-weight: 600;
    }

    &__time {
      display: grid;

      &__item {
        margin-bottom: 2px;

        &:not(:first-child) {
          margin-top: 13px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.image {
  height: 200px;
  width: 100%;
  background-position: center;
  background-size: cover;
}
