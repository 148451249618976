@import '../../../../core/styles/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.page {
  display: flex;
  flex: 1;
  height: 100%;
  overflow-y: hidden;
  justify-content: center;
}
