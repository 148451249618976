@import '../../core/styles/breakpoints';

.root {
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  direction: ltr;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 40px;
  min-width: 200px;
  background-color: white;
  z-index: 2;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.05);
  max-height: calc(100vh - 200px);
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 8px;
    border: 1px solid #efefef;
    overflow: auto;
  }
}

.angle {
  position: absolute;
  top: -5px;
  height: 10px;
  width: 10px;
  right: 10px;
}

.leftAngle {
  right: initial;
  left: 10px;
}

.angleIcon {
  border-left: solid 1px #efefef;
  border-top: solid 1px #efefef;
  background: white;
  transform: rotate(45deg);
  height: 10px;
  width: 10px;
}

.icon {
  transform: rotate(90deg);
  height: 10px;
  width: 10px;
}

.row {
  padding: 16px 4px;
  border-bottom: 1px dashed #efefef;
  font-size: 12px;
  color: black;

  &:last-of-type {
    border-bottom: none !important;
  }
}

.leftWrapper {
  right: initial;
}

.label {
  display: flex;
  align-items: center;
  margin-right: 5px;
  white-space: nowrap;
  cursor: pointer;

  @media (min-width: $lg-breakpoint) and (orientation: landscape) {
    margin-right: 10px;
  }
}
