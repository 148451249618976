.my-order-summary {
  width: 100%;
  box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1);

  &__inclusive-taxes {
    margin-left: 16px;

    p {
      font-size: 12px;
    }
  }

  &__total {
    p {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__wrapper {
    margin: 0 40px;
    height: calc(100% - 48px);

    &__charges-info {
      padding: 20px 0 15px;

      div {
        padding: 5px 0;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;
      font-size: 14px;
    }

    &__total {
      display: flex;
      justify-content: space-between;
      margin: 15px 0 0;
    }
  }
}
