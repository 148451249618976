.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.indicatorFieldRound {
  height: 17px;
  width: 17px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-radius: 50px;
  border-style: solid;
  padding: 3px;
}

.dot {
  height: 100%;
  width: 100%;
  border-radius: 50px;
}

.titleField {
  display: flex;
  flex-direction: row;
  flex: 2;
  justify-content: space-between;
  align-items: center;
  padding-left: 4.5px;
  color: #000000;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: bold;
  line-height: 48px;
}

html[dir='rtl'] {
  .titleField {
    padding-left: 0;
    padding-right: 4.5px;
  }
}
