.location-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 104px;

  &__tab {
    white-space: nowrap;
    max-width: 100vw;
    min-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 28px -16px 10px;

    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    &__arrow {
      position: absolute;
      right: 0;
      padding: 10px 10px 10px 50px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0.001), white);
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.2s;

      &__visible {
        opacity: 1;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      padding: 7px 8px;
      margin: 0 14px;

      &__selected {
        border-radius: 13px;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__time-selection-button {
    margin-top: 0;
  }
}

.locationPageIframe {
  margin-top: 0px;
}
