.root {
  display: block;
  font-family: 'Open Sans';
  padding: 20px 16px 16px 16px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #191c1d;
  text-align: left;
}

.header-container {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.header-title {
  font-size: 14px;
  color: #191c1d;
}

.container {
  padding: 16px 0;
}

.button-action {
  margin-bottom: 16px;
}
