@import '../../../../../core/styles/mixins';

.payment-method-choose {
  margin-top: 40px;

  &__list {
    padding: 0 16px 24px;
    margin: 0;
    overflow: auto;
  }

  &__list-item {
    list-style: none;
    border: 1px solid #777777;
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    font-family: 'Open Sans';
    font-size: 16px;
    padding: 13px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    & + & {
      margin-top: 16px;
    }

    &__icon {
      transform: rotateZ(-90deg);
    }
  }
}

@include bp(lg) {
  .payment-method-choose {
    margin-top: 19px;

    &__list-item {
      justify-content: center;
    }
  }
}
