@import '../../../../core/styles/breakpoints';

.alcoholModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  overflow: hidden;

  &__icon {
    position: absolute;
    top: 10px;
    right: 15px;
    width: 13px;
    height: 13px;

    svg {
      height: 10px;
      width: 10px;
    }
  }

  &__title {
    margin-top: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__description {
    text-align: center;
    font-weight: 300;
    line-height: 22px;
    margin: 20px 0;
  }

  &__buttons {
    display: flex;
    width: 100%;

    &__dismiss {
      margin-right: 7px;
    }

    &__submit {
      margin-left: 7px;
    }
  }

  &__dont-ask-again {
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;
  }
}
