.order-page-item {
  padding: 20px 18px;
  font-size: 14px;
  border-bottom: 1px dashed #e3e3e3;
  color: #1d1b20;
  cursor: pointer;

  &__general {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &__leftSide {
      display: flex;
      align-items: center;

      &__editCircle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
        width: 21px;
        height: 21px;
        border-radius: 23px;
      }

      &__title {
        width: calc(100% - 15px);

        &__x {
          padding: 0 5px;
        }
      }
    }

    &__price {
      text-align: right;
      margin-left: 5px;
      font-weight: 400;
    }
  }

  &__desc {
    line-height: 17px;
  }

  &__addons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 6px 0 0 44px;
  }

  &__comment {
    margin: 11px 20px 0 42px;
    overflow: hidden;
  }
}

.order-page-item-desktop {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  padding: 10px 0;
  cursor: pointer;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none !important;
  }

  &__addons {
    &__title {
      margin-left: 10px;
      margin-top: 5px;
    }
  }
}

html[dir='rtl'] {
  .order-page-item {
    &__general {
      &__leftSide {
        &__editCircle {
          margin-left: 7px;
          margin-right: 0;
        }
      }
    }

    &__addons {
      margin-left: 6px;
      margin-right: 44px;
    }
  }
}
