@import '../../../../core/styles/mixins';

.root {
  margin: 16px;
  background: white;
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;

  &__isNative {
    margin: 29px 16px 16px;
  }

  &__product-info {
    color: #1d1b20;
    font-family: 'Open Sans';
    line-height: normal;
    font-style: normal;
    width: 100%;
    cursor: default;

    &__title {
      font-size: 16px;
      font-weight: 600;
    }

    &__calories {
      color: #1d1b20;
      font-size: 14px;
      font-weight: 400;

      &::before {
        content: '\2022';
        padding: 5px;
      }
    }

    &__description {
      font-size: 14px;
      font-weight: 400;
    }

    &__footer {
      display: inline-flex;
      width: 100%;
      padding-top: 16px;

      &__price {
        color: #1d1b20;
        font-size: 16px;
        font-weight: 700;
        width: 30%;
      }
    }
  }
}

@include bp(lg) {
  .root {
    width: 448px;
  }
}
