.phone-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  line-height: 17px;

  &__description {
    width: auto;
    height: 51px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-family: 'Open Sans';
    line-height: normal;
    font-size: 16px;
    margin: 32px 0 17px;
  }
}
