@import '../../core/styles/breakpoints';

.picker-column {
  flex: 1 1;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  text-align: left;

  &:nth-child(2n) {
    text-align: right;
  }

  &__picker-scroller {
    transition: 300ms;
    transition-timing-function: ease-out;
  }

  &__pickerItem {
    position: relative;
    white-space: nowrap;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 20px;

    &__selected {
      color: #222;
    }
  }
}

@media (max-width: $lg-breakpoint) {
  .picker-column {
    &:nth-child(2n) {
      text-align: left;
      padding-left: 8px;
    }

    &:first-child {
      text-align: right;
      padding-right: 8px;
    }

    &__pickerItem {
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 22px;
      color: #777777;

      &__selected {
        color: #1d1b20;
        font-family: 'Open Sans Bold';
        font-weight: 700;
      }
    }
  }
}
