@import '../../../../core/styles/mixins';
@import '../../../../core/styles/breakpoints';

:global(.iframe) .location-content {
  height: 100%;
}

.locationContent {
  height: calc(100vh - 170px);
  padding: 0px 16px 0 16px;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.experienceDropdown {
  padding: 16px 0px;
}

.restaurants {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@include bp(lg) {
  .locationOverflow {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1600px;
  }
}

@include bp(xl) {
  .restaurants {
    padding-top: 12px;
  }
  .experienceDropdown {
    padding-bottom: 0px;
  }
}
