.quantity {
  width: auto;
  align-self: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 16px 0;
  pointer-events: initial;
  cursor: default;

  &__title {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  &__button-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__button {
      border-radius: 50%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      border: none;
      height: 32px;
      cursor: pointer;
    }

    &__disabled-button {
      color: #777777;
    }

    &__number {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0 16px;
      z-index: 1;
      width: 32px;
      height: 32px;
      color: #1d1b20;
      font-family: 'Open Sans';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

html[dir='rtl'] {
  .quantity {
    &__button-wrap {
      flex-direction: row-reverse;
    }
  }
}
