@import '../../../../core/styles/breakpoints.scss';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 6;

  &__custom-button {
    position: static;
    margin: 0 auto 16px;
    z-index: 3;
    border: none;
  }
}

.register-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 88px 16px 0;
  background-color: #ffffff;
  height: calc(100% - 48px);
  overflow-y: auto;

  &__form {
    width: 100%;
    height: auto;
  }

  &__form_extended {
    flex: 1;
  }
}

@media (max-width: $lg-breakpoint) {
  .root {
    position: fixed;
  }
}

@media (max-width: $md-breakpoint) {
  .register-page {
    padding-top: 34px;
  }
}
