.root {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 50px 10px 10px;
  max-width: 350px;
  text-align: center;
}

.confirm-button {
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  margin-top: 20px;
  border-top: 1px solid #efefef;
}

.cancel-button {
  @extend .confirm-button;
  margin-top: 10px;
}
