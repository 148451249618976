@import '../../../../core/styles/mixins';

.allergen-modal {
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0;

  &__title {
    padding: 22px 0 22px;
    color: #1d1b20;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__icon {
    position: absolute;
    top: 32px;
    right: 20px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  &__subtext {
    padding: 0 25px 20px 25px;
    text-align: center;
    color: #1d1b20;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__container {
    width: 100%;
    flex-wrap: wrap;
    overflow-y: auto;
    padding: 0 40px 80px;

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0 24px 0;
      flex-basis: 50%;
      color: #1d1b20;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &__abb {
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 6px;
      }

      &__item {
        font-size: 12px;
      }
    }
  }
}

@include bp(lg) {
  .overlay1 {
    top: 64px;
  }

  .allergen-modal {
    position: relative;

    &__title {
      padding: 15px 0 20px;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }

    &__subtext {
      padding: 0 16px 16px 16px;
    }

    &__container {
      padding: 60px 32px 80px;
    }

    &__CustomButton {
      position: absolute;
    }
  }
}
