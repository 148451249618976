.root {
  display: flex;
  float: right;

  &__text {
    margin-left: 10px;
    display: flex;
    align-items: center;
    margin-top: -2px;
    color: #1d1b20;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__arrow-icon {
    padding-left: 10px;
  }

  &__picker {
    max-width: 295px;
    max-height: 350px;
    min-width: 170px;
    overflow-y: auto;
    border-radius: 8px;
    background-color: #ffffff;
    position: absolute;
    right: 16px;
    top: 60px;
    padding: 8px 0;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.25);
  }
}
