.phone-input__container {
  width: 100%;
  height: 48px;
  min-width: 280px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 4px;

  &:focus {
    box-shadow: none !important;
  }

  &__disabled {
    background: #efefef;
  }

  &__icon {
    height: 12px;
    position: absolute;
    pointer-events: none;
    z-index: 1;

    svg {
      margin-left: 30px;
      width: 12px;
      height: 12px;
      color: #2672cd;
      transform: rotate(90deg);
    }

    &_disabled {
      height: 12px;
      position: absolute;
      pointer-events: none;
      z-index: 1;

      svg {
        margin-left: 30px;
        width: 12px;
        height: 12px;
        color: #787878;
        transform: rotate(90deg);
      }
    }
  }

  &__input {
    border-radius: 4px !important;
    width: 100% !important;
    margin: 0;
    outline: none;
    line-height: 18px !important;

    background-color: #ffffff;
    height: 48px;
    color: #1d1b20;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    &::placeholder {
      color: #bb0e2d;
    }
  }

  &__dropdown {
    width: 250px !important;
    height: 120px !important;
  }
}

.custom-wrap {
  width: 42px;
  height: 20px;
  overflow: hidden;

  & > div {
    padding: 0 !important;
    width: 42px !important;
    left: 4px !important;
    background: inherit !important;

    & > div {
      left: 2px !important;
    }
  }
}

.arrow-icon {
  position: absolute;
  pointer-events: none;
  left: 42px;
  top: 21px;
  z-index: 2;

  svg {
    width: 10px;
    height: 6px;
  }

  &_disabled {
    svg {
      color: #787878;
    }
  }
}

.dropdownButton {
  width: 47px;
  height: 20px;
  background-color: #fff;
  border: none !important;
  top: 16px !important;
  left: 5px;

  &::after {
    content: '';
    position: absolute;
    left: 23%;
    top: 16px;
    transform: translate(-50%, -50%);
    // border-radius: 50%;
    width: 20px;
    height: 20px;
    // border: 9px solid #ffffff;
    pointer-events: none;
  }

  &_colored {
    background-color: #f5f5f5;

    &::after {
      border: 9px solid #f5f5f5;
    }
  }

  &_disabled::after {
    border-color: #efefef;
  }
}

.form {
  position: relative;
  width: 100%;
  margin-top: 23px;
  outline: none;
  background: transparent;
  box-sizing: border-box;

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px #ffffff inset !important;
  }

  .form-label {
    position: absolute;
    left: 60px;
    top: 16px;
    transition:
      transform 150ms ease-out,
      font-size 150ms ease-out;
    z-index: 1;
    pointer-events: none;
    color: #777777;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &.focused .form-label {
    padding: 0 2px;
    transform: translateY(-26px) translateX(-55px);
    background-color: #ffffff;
    font-size: 14px;
  }

  .optional-label {
    font-family: Open Sans;
    position: absolute;
    top: -18px;
    right: 2px;
    color: #838383;
    font-size: 12px;
  }

  .form-input {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 7px;
    outline: 0;
    border: none;

    &::placeholder {
      color: transparent;
    }

    &:disabled {
      background: none;
    }
  }
}

:global {
  .flag-dropdown {
    background: inherit !important;
  }

  .flag-dropdown.open {
    background: inherit !important;
  }

  .react-tel-input .form-control:focus {
    box-shadow: none !important;
  }
}

html[dir='rtl'] {
  :global {
    .react-tel-input {
      direction: ltr;

      .flag-dropdown {
        left: 45px;
      }

      .form-control {
        padding-left: 100px;
      }

      .country-list {
        direction: rtl;
      }
    }
  }

  .form {
    .form-label {
      right: 8px;
      left: initial;
    }

    &.focused .form-label {
      transform: translateY(-180%);
    }
  }

  .arrow-icon {
    left: 80px;
  }
}
