.email-screen {
  height: 42px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  input::placeholder {
    font-family: 'Open Sans';
    font-size: 13px;
    line-height: 18px;
    color: #0f0f0f;
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #ffffff inset !important;
  }
}

.input {
  margin-top: 0;
  padding: 0 48px 0 8px;
}
