.options-page {
  position: relative;

  &__container {
    padding: 0 16px 40px 16px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    overflow: hidden;
  }

  &__background {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    max-height: 30vh;
    min-height: 30vh;
  }

  &__background-image {
    width: 100%;
    max-height: 30vh;
    min-height: 30vh;
  }
}
