.icon {
  padding: 0 16px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.profileIcon {
  height: 16px;
  width: 16px;
  margin-right: 16px;
}

.row {
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
}
