@import '../../../../../core/styles/mixins';

.header {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 27px 16px 10px;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.05);

  &__actions {
    display: flex;
    position: absolute;
    right: 16px;
  }

  &__profile {
    position: absolute;
    right: 32px;
  }

  &__lang {
    position: absolute;
    right: 16px;
  }

  .BackButton {
    width: 100%;
  }

  &__title {
    position: absolute;
    left: 70px;
    right: 70px;
    text-align: center;
  }

  &__icon {
    margin-right: 30px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@include bp(lg) {
  .header {
    justify-content: flex-end;
    padding: 30px;
    border-bottom: none;
    height: auto;

    &__profile,
    &__lang {
      position: unset;
      right: 0;
    }

    h1 {
      display: none;
    }
  }
}
