@import '../../../../core/styles/mixins';

.container {
  font-size: 11px;
  line-height: 19px;
  flex: 1;
  display: flex;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;

  .text {
    max-height: 252px;
    overflow-y: auto;
    vertical-align: sub;
  }
}

@include bp(lg) {
  .container {
    .text {
      max-height: 150px;
    }
  }
}
