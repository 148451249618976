@import '../../core/styles/breakpoints';

.data-grid {
  width: 100%;
  height: calc(100% - 57px);
  margin-top: 57px;
  overflow-y: auto;

  &__header {
    position: sticky;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 20px;
    margin: 15px 20px 0;
    background-color: #d9d9d9;

    @media (min-width: $lg-breakpoint) {
      padding: 10px 40px;
      margin: 0;
    }

    &__column {
      width: calc(100% / 3);
      flex-grow: 1;
      text-align: center;
      font-size: 12px;
      line-height: 2;

      &:first-of-type {
        text-align: start;
      }

      &:last-of-type {
        text-align: end;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 15px;

    &__row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 25px 0;
      margin: 0 40px;
      justify-content: space-between;
      cursor: pointer;

      @media (min-width: $lg-breakpoint) {
        padding: 25px 40px;
        margin: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px dashed #d9d9d9;
      }

      &:hover {
        background-color: whitesmoke;
      }

      div {
        text-align: center;
        width: 50%;

        &:first-of-type {
          text-align: start;
          width: 25%;
        }

        &:last-of-type {
          text-align: end;
          width: 25%;
        }
      }
    }

    &__loader {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
