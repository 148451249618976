@import '../../core/styles/breakpoints';

.menu-header {
  &.hasNative {
    padding-top: 44px;
  }

  &.hasIframe {
    padding: 10px 16px 10px 16px;
  }

  &.noIframeDisplay {
    display: none;
  }

  display: flex;
  height: 57px;
  padding: 17px;
  z-index: 1;
  width: 100%;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);

  &__wrapper {
    display: flex;
    height: 30px;
    align-items: center;
    position: relative;
  }

  &__border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  &__icon {
    position: absolute;
    left: -8px;
  }

  &__title {
    flex: 1 1 100%;
    text-align: center;
    margin: 0 30px;

    @media (min-width: $lg-breakpoint) and (orientation: landscape) {
      text-align: left;
      margin: 0;
    }
  }
}

.header {
  height: 56px;
  min-height: 56px;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 16px;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
  z-index: 2;

  &__isNative {
    padding-top: 44px;
    padding-bottom: 25px;
  }

  &__title {
    flex-grow: 1;
    text-align: center;
    font-size: 16px;
    font-family: 'Open Sans Bold';
    font-weight: 700;
  }

  &__backButton {
    width: 10px;
    cursor: pointer;
  }

  &__rightElement {
    display: flex;

    path {
      fill: #fff;
    }
  }
}

.desktop-header {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 30px 30px;
  height: 80px;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.05);
  z-index: 1;

  &__logo {
    flex: 1 1 auto;
    margin-right: 30px;
    height: 100%;
    align-items: center;
    display: flex;

    &__image {
      cursor: pointer;
    }
  }

  &__title {
    font-family: 'Open Sans';
    font-size: 18px;
    flex-grow: 1;
    font-weight: 700;
    text-transform: uppercase;
  }

  &_small {
    &__icon {
      position: absolute;
      top: 0;
      right: 0;
      padding: 16px;
      cursor: pointer;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    &__title {
      text-align: center;
      color: #fff;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: default;
      flex-grow: 1;
    }
  }
}

.additionalTitle {
  text-align: center;
}

.rightContainer {
  position: absolute;
  right: -8px;
}

html[dir='rtl'] {
  .menu-header {
    &__icon {
      left: 0;
    }
  }
}
