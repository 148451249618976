@import '../../../../core/styles/mixins';

.menu-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  background: #ffffff;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
  padding-left: 17px;
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__lable {
    color: #1d1b20;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 16px;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 32px;

    &__arrow {
      &__down {
        margin-left: 8px;

        svg {
          height: 6px !important;
          width: 10px !important;
        }
      }

      &__right {
        margin-left: 8px;
        width: 10px;

        svg {
          height: 10px !important;
          width: 6px !important;
        }
      }

      &__open {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  &__selected {
    color: #ffffff;
    padding: 6px 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin: 0;
  }

  &__dropdown {
    position: absolute;
    top: 108px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 8px 0;
    display: grid;
    overflow-y: scroll;
    max-height: 367px;

    &__item {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      height: 32px;
      padding: 6px 30px;
      margin: 8px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      color: #1d1b20;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border: 1px solid #777777;
      background: #ffffff;

      &__subcategories {
        padding: 6px 16px 6px 30px;
        margin-right: 16px;
      }

      &__more-subcategories {
        border-left: 1px solid rgba(216, 216, 216, 0.85);
        overflow-y: scroll;

        &__item {
          justify-items: flex-start;
        }
      }

      &__span {
        width: 100%;
      }
    }
  }
}

@include bp(lg) {
  .menu-tab {
    border-bottom: none;
    box-shadow: none;
    padding-left: 0;
    height: 40px;
    cursor: pointer;

    &__lable {
      height: 40px;
      font-size: 16px;
    }

    &__dropdown {
      top: 122px;
      max-height: 367px;

      &__item {
        border-radius: 0;
        margin: 0 8px;
        height: 48px;
        border: 0;
        justify-content: flex-start;

        &__selected {
          border: none;
          background: #f1f1f1;
        }
      }
    }
  }
}

.icon {
  height: 13px;
  width: 13px;
  transform: rotate(90deg);
}

.moreCategory {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
