@import '../../../../core/styles/breakpoints.scss';

.edit-profile-page {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 6;
}

.edit-profile {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__form {
    &__button {
      width: 100%;
      height: 48px;
      margin-top: auto;
      border: none;
      color: #ffffff;
    }

    &__container {
      padding: 32px 16px 0;
      height: 100%;

      &__input-container {
        width: 100%;
        height: 42px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        input {
          background: transparent;
          width: 100%;
        }

        input:-webkit-autofill {
          box-shadow: 0 0 0 1000px #f6f6f6 inset !important;
        }
      }
    }
  }
}

.delete-account {
  width: fit-content;
  text-align: center;
  margin: 16px auto 0;
  cursor: pointer;
}

.button-group-container {
  margin-top: 35px;
  & > div {
    align-items: start;
    padding-bottom: 16px;
  }
}

@media (max-width: $lg-breakpoint) {
  .edit-profile {
    &__form {
      &__container {
        padding-top: 16px;
      }
    }
  }
  .button-group-container {
    margin-top: 24px;
  }
}

@media (max-width: $md-breakpoint) {
  .edit-profile {
    &__form {
      &__button {
        position: absolute;
        bottom: 0;
      }
    }
  }
}
