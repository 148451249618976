.header-navigation {
  background: #ffffff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 4;

  &__logo {
    height: 40px;
    background: #ffffff;
    margin: 12px 16px 12px 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__image {
      height: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__title,
  &__subTitle {
    font-size: 20px;
    line-height: 27px;
    color: #1d1b20;
    cursor: default;
  }

  &__title {
    font-family: 'Open Sans Semibold';
  }

  &__subTitle {
    font-family: 'Open Sans';
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: -1;
  }

  &__action {
    position: absolute;
    right: 27px;
    display: flex;
  }
}
