@import '../../../../core/styles/mixins';

.checkout-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  background-color: #ffffff;

  &__scroll {
    padding-top: 56px;
    margin-bottom: 48px;
    overflow: auto;
    background-color: #ffffff;
    padding-bottom: 19px;
  }

  &__register-wrap {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #e3e3e3;

    &__title {
      margin-right: 5px;
    }

    &__register {
      font-weight: bold;
    }
  }

  &__info-wrap {
    display: flex;
    flex-direction: column;
    padding: 16px;
    text-align: center;
    color: #1d1b20;
    font-family: Open Sans;
    font-style: normal;
    line-height: normal;
    border-bottom: 0.65px dashed #e3e3e3;

    &__experiance {
      font-size: 16px;
      font-weight: 700;
    }

    &__time,
    &__restaurant {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
    }

    &__time {
      display: inline-flex;
      justify-content: center;
      justify-items: center;

      &__icon {
        margin-right: 8px;

        svg {
          width: 16px !important;
          height: 16px !important;
        }
      }
    }
  }
}

@include bp(lg) {
  .checkout-page {
    height: 100%;

    &__scroll {
      padding-top: 0;
    }
  }
}
