@import '../../../../core/styles/breakpoints';
@import '../../../../core/styles/mixins';

.menuPage {
  position: relative;
  width: 100%;

  @media (max-width: $lg-breakpoint) and (orientation: landscape) {
    flex: 1 1 100%;
  }

  &__rightIcons {
    display: flex;
    align-items: center;

    &_cart {
      margin-left: 15px;
    }
  }

  .hasIframe {
    padding-top: 60px;
  }
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  cursor: default;

  @media (min-width: $lg-breakpoint) and (orientation: landscape) {
    overflow: hidden;
  }
}

.mobileHeader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.mobilePageWrapper {
  padding-top: 104px;
}

.wrapper {
  max-width: 1200px;
  width: 100%;
}

.checkoutWrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  overflow-y: auto;
}

.button {
  position: static;
  width: initial;
  margin: 10px;
  padding-left: 0;
}

.viewModeBox {
  width: 300px;
  padding: 10px;
  margin: 10px 60px;
  font-size: 12px;
  color: black;
  line-height: 20px;
  text-align: center;
  background: rgb(222, 222, 222);
  position: absolute;
  bottom: 30px;
}

.additionalTitle {
  margin-top: 15px;
}

@include bp(lg) {
  .pageWrapper {
    padding-bottom: 68px;
    padding-left: 24px;
    overflow: auto;
    width: 100%;

    .checkoutWrapper {
      height: calc(100vh - 128px);
      width: 420px;
      position: absolute;
      top: 129px;
      right: 0;
      overflow-y: auto;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
  }
}

html[dir='rtl'] {
  .rowDesktopTime {
    direction: ltr;
    display: flex;
    justify-content: flex-end;
  }

  .timeText {
    padding-right: 0;
    padding-left: 15px;
    padding-top: 3px;
  }
}
