@import '../../core/styles/breakpoints';

.profile-menu {
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  &__item {
    display: flex;
    align-items: center;
    padding: 24px 0;
    color: #1d1b20;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;

    &:not(:last-child) {
      border-bottom: 1px solid #f1f1f1;
    }

    &__icon {
      display: flex;
      justify-content: center;
      margin-right: 8px;
    }

    &__arrow-icon {
      position: absolute;
      right: 16px;
    }
  }
}

html[dir='rtl'] {
  .profile-menu {
    &__item {
      direction: rtl;

      &__icon {
        margin-right: initial;
        margin-left: 8px;
      }
    }
  }
}
