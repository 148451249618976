.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.wrapperInput {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.label {
  font-size: 10px;
  margin-bottom: 8px;
}

.customInput {
  padding: 2px 8px;
}

.custom-input {
  width: 100%;
  height: 45px;
  padding: 0 8px;
  border: none;
  border-radius: unset;
  outline: none;
  font-size: 13px;
  line-height: 18px;
  background: white;
  box-sizing: border-box;

  &[disabled] {
    background-color: #efefef;
  }
}

.error-message {
  font-size: 11px;
  margin-top: 8px;
}
