.element {
  margin: 0 16px 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 6px 12px 2px rgba(0, 0, 0, 0.07);
  color: #1d1b20;
  font-family: Open Sans;
  font-style: normal;
  line-height: normal;

  &__title {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
  }

  &__value {
    font-size: 16px;
    font-weight: 400;

    a {
      color: #027bfd;
      text-decoration: none;
    }
  }
}
