.wrap {
  position: relative;
  display: flex;
  cursor: pointer;
  margin-top: 8px;
}

.checkbox {
  align-items: flex-start;

  input {
    display: none;
  }
}

.checkboxCustom {
  position: absolute;
  margin-top: 16px;
  pointer-events: none;
}

.label {
  cursor: pointer;
  padding-left: 30px;
  user-select: none;

  a {
    text-decoration: none;
    color: #2672cd;
  }
}

html[dir='rtl'] {
  .label {
    padding-right: 30px;
    padding-left: initial;
  }
}
