.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  height: calc(100vh - 64px);

  .qr_code_icon {
    margin-bottom: 40px;
  }
}

.timeout-title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #1e7dba;
}

.timeout-subtitle {
  font-size: 20px;
  font-weight: 600;
}
