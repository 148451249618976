@import '../../../../core/styles/breakpoints';

.home-page__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 48px;

  &__wrapper {
    width: 100%;
    max-width: 600px;
    padding: 0 50px;
    margin: 24px auto 0;

    &.restaurantFlow {
      flex-direction: column;
    }

    @media (min-width: $lg-breakpoint) and (orientation: landscape) {
      display: flex;
      max-width: 800px;
      margin-bottom: 30px;
      margin-top: 15px;
    }

    &__hotel-select {
      @media (min-width: $lg-breakpoint) and (orientation: landscape) {
        margin-top: 0;
        padding-right: 16px;
        width: 100%;
      }
    }

    &__time-wrapper {
      @media (min-width: $lg-breakpoint) and (orientation: landscape) {
        margin-top: 0;
        width: 100%;
      }

      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      & > div:first-child {
        margin-right: 16px;
      }
    }

    &__experience {
      height: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  &__restaurant-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;

    &__part {
      display: flex;
      flex: calc(50% - 20px);
      margin: 10px;
      justify-content: space-between;

      @media (max-width: $lg-breakpoint) {
        flex: 100%;
        margin: 20px 0 0 0;
      }

      &__date-time {
        flex: 0 0 calc(50% - 10px);

        .select-box__menu {
          height: 220px;

          &-list {
            height: 100%;
            overflow-y: auto;
          }
        }
      }

      button {
        margin: 0;
        width: 100%;
        max-width: none;
      }
    }
  }

  &__poweredBy {
    align-self: center;
    margin: auto 0 0;

    @media (min-width: $lg-breakpoint) and (orientation: landscape) {
      margin: auto 0 70px;
      order: 2;
    }
  }

  &__links {
    display: flex;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 15px;
    margin-top: 36px;
    margin-bottom: 20px;

    @media (min-width: $lg-breakpoint) and (orientation: landscape) {
      margin-top: 30px;
      order: 1;
    }

    a {
      text-decoration: underline;

      &:first-child {
        margin-right: 2px;
      }

      &:last-child {
        margin-left: 2px;
      }
    }
  }
}

.logoContainer {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  display: flex;
  margin-bottom: 24px;
}

.titleContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 48px;
}

.logo {
  max-width: 150px;

  @media (min-width: $lg-breakpoint) and (orientation: landscape) {
    max-width: 300px;
    margin-bottom: 40px;
  }
}

.title {
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
}

html[dir='rtl'] {
  .home-page__form {
    &__links {
      a:first-child {
        margin-right: 0;
        margin-left: 2px;
      }
      a:last-child {
        margin-left: 0;
        margin-right: 2px;
      }
    }
  }
}

.homePageButtonOrder {
  position: fixed;
  bottom: 0;
  left: 0;

  @media (min-width: $lg-breakpoint) and (orientation: landscape) {
    position: relative;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 300px;
  }
}
