.root {
  height: 300px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  padding: 0 24px;
  text-align: center;
}

.titleClosed {
  @extend .title;
  margin-bottom: 24px;
}

.body {
  margin: 20px 0 15px;
  font-weight: 700;
  direction: ltr;
}
