.checkbox-wrap {
  position: relative;
  display: flex;
  flex-direction: row;
  font-family: 'Open Sans';
  font-size: 13px;
  line-height: 18px;
  text-align: left;

  &__checkbox {
    margin-right: 9px;
    height: 18px;

    input {
      opacity: 0;
      cursor: pointer;
    }

    input:checked ~ &_custom {
      background-color: #ffffff;
      transform: rotate(0deg) scale(1);
    }

    input:checked ~ &_custom::after {
      transform: rotate(45deg) scale(1);
      opacity: 1;
      left: 4px;
      top: 0px;
      width: 5px;
      height: 10px;
      border-width: 0 2px 2px 0 !important;
      background-color: transparent;
    }

    &_custom {
      position: absolute;
      top: 0;
      left: -2px;
      background-color: transparent;
    }

    &_custom::after {
      position: absolute;
      content: '';
      left: 8px;
      top: 8px;
      transform: rotate(0deg) scale(0);
      opacity: 1;
    }
  }

  &__label {
    a {
      text-decoration: none;
      color: #2672cd;
    }
  }
}

html[dir='rtl'] {
  .checkbox-wrap {
    &__checkbox {
      &_custom {
        left: initial;
        right: 2px;
      }
    }
  }
}
