@import '../../../../core/styles/mixins';

.checkout-page__form {
  display: flex;
  flex-direction: column;

  &__user-info {
    padding: 8px 16px 16px 16px;
    border-bottom: 0.65px dashed #e3e3e3;

    &__title {
      color: #1d1b20;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 16px;
    }

    &__label {
      padding: 12px 0 8px;
      font-size: 10px;
    }

    &__disclaimer {
      color: #1d1b20;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-top: 8px;
    }

    &__allergen-comment {
      width: 100%;
      height: 90px;
      resize: none;
      border: 1px solid #121212;
      padding: 0;
    }

    &__additional-comment {
      height: 120px;
      resize: none;
      padding: 0;

      textarea {
        margin: 0;
      }
    }
  }

  &__terms-and-conditions {
    padding: 16px 16px 16px 17px;

    &__text {
      color: #1d1b20;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__custom-button {
    z-index: 3;
    border: none;
  }
}

.errorMessage {
  padding: 0 18px 8px 18px;
  color: #bb0e2d;
}

@include bp(lg) {
  .checkout-page__form {
    &__custom-button {
      position: absolute;
      bottom: 16px;
      width: 448px;
    }
  }
}

html[dir='rtl'] {
  .lastName {
    margin-left: 0;
    margin-right: 16px;
  }
}
