:global {
  .select-box {
    &__control {
      border-radius: 4px !important;
      height: 48px;
      background: #fff;
      color: #1d1b20;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &__placeholder {
      color: #777777;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &__menu {
      margin-top: 0 !important;
      z-index: 2 !important;
    }

    &__menu-list {
      border: 1px solid #121212;
      border-top: none;
    }

    &__value_container {
      align-items: center;
    }

    &__option {
      padding: 12px 0 !important;
      margin: 0 10px;
      border-bottom: 1px dashed lightgrey;
      width: auto !important;

      &:last-child {
        border-bottom: none;
      }

      &--is-focused,
      &--is-selected {
        background-color: inherit !important;
      }
    }
  }
}

.selectBox__container {
  width: 100%;
  position: relative;
  cursor: pointer;

  .form-label {
    position: absolute;
    left: 7px;
    top: -10px;
    z-index: 2;
    pointer-events: none;
    padding: 0 2px;
    color: #777777;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background-color: #ffffff;
  }

  .optional-label {
    position: absolute;
    top: -18px;
    right: 2px;
    color: #838383;
    font-size: 14px;
    font-weight: 400;
  }
}

.select-box-arrow {
  transform: rotate(90deg);
}

.icon {
  margin: 0 16px;
}

.name {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.description {
  margin-top: 0;
}

.input-onclick {
  border: 1px solid #121212;
  height: 45px;
  width: 100%;
  padding: 2px 8px;

  &[disabled] {
    background: inherit;
  }

  &::placeholder {
    color: #787878;
    font-size: 13px;
  }
}

.row {
  display: flex;
  align-items: center;
  position: relative;
}

.search-icon {
  position: absolute;
  right: 8px;
}

html[dir='rtl'] {
  .selectBox__container {
    .form-label {
      right: 7px;
      left: initial;
    }

    .optional-label {
      right: initial;
      left: 2px;
    }
  }

  .search-icon {
    right: initial;
    left: 8px;
  }
}

.option-loader {
  height: 20px;
  div {
    height: 25px;
  }
}
