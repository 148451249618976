@import '../../../../core/styles/mixins';

.order-summary {
  padding: 18px 17px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  line-height: 17px;
  border-bottom: 1px dashed #e3e3e3;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__title {
      margin-right: 4px;
      flex: 1;
      color: #1d1b20;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__disclaimer {
    color: #777777;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 10px;
    padding-bottom: 18px;
  }

  &__container {
    display: flex;
    flex-direction: column;

    &__item {
      &__isInclusive {
        margin-left: 16px;
      }

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 8px;

      &__name,
      &__value {
        color: #1d1b20;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &__isInclusive {
          font-size: 14px;
        }
      }
    }
  }

  &__buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 18px 0 0 0;

    &__no-tip,
    :nth-child(5) {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 4px;
      display: -webkit-box;
      word-break: break-word;
    }

    &__no-tip {
      border-radius: 8px 0 0 8px;
      width: calc((100% - 159px) / 2) !important;
      border-width: 1px 0 1px 1px !important;
    }

    :nth-child(5) {
      border-radius: 0 8px 8px 0;
      width: calc((100% - 159px) / 2);
      border-width: 1px;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 53px;
      cursor: pointer;
      color: #fff;
      text-align: center;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-style: solid;
      border-width: 1px 0 1px 1px;
    }
  }
}

.root {
  position: relative;
}

@include bp(lg) {
  .order-summary {
    &__buttonGroup {
      &__no-tip,
      :nth-child(5) {
        width: 114px !important;
      }

      &__button {
        width: 71px;
      }
    }
  }
}
