@import '../../../../core/styles/mixins';

.payments-method {
  &__container {
    position: absolute;
    bottom: 0;
    background-color: #ffffff;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: visible;
    border-radius: 16px 16px 0 0;

    &__header {
      &__choose-title {
        margin: 28px 0 0 24px;
        color: #191c1d;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &__choose-title-subtitle {
        margin: 16px 0 0 24px;
        font-size: 20px;
        font-weight: 600;
      }

      &__title {
        margin: 28px 0 0 24px;
        letter-spacing: 0;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #1d1b20;
      }

      &__exit {
        position: absolute;
        top: 32px;
        right: 24px;

        img {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}

@include bp(lg) {
  .payments-method {
    &__container {
      position: unset;
      border-radius: 12px;

      &__wallet {
        border-radius: 0;
      }

      &__header {
        &__exit {
          position: relative;
          top: -60px;
          left: 326px;

          &__wallet {
            top: -21px;
            left: 397px;
          }
        }
      }
    }
  }
}
