@import '../../../../core/styles/variables.module';
@import '../../../../core/styles/mixins';

.coupon {
  border-top: 1px solid #eff1f1;
  border-bottom: 1px solid #eff1f1;
  height: 64px;
  padding: 21px 16px;

  &__box {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    display: inline-flex;
    justify-content: left;
    justify-items: left;
    cursor: pointer;
    color: $success;
    width: 100%;

    &__name {
      width: 448px;
    }

    &__verify {
      font-weight: 700;
    }

    span {
      padding-left: 8px;
      line-height: 21px;
    }

    &__icon {
      display: contents;

      svg {
        width: 21px;
        height: 21px;
      }
    }

    &__icon-close {
      position: absolute;
      right: 16px;
      width: 16px;
      padding-top: 6px;
      height: 16px;

      svg {
        fill: $success;
      }
    }
  }
}

@include bp(lg) {
  .coupon {
    &__box {
      &__icon-close {
        position: relative;
        right: 0;
      }
    }
  }
}
