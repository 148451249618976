.text {
  margin-bottom: 8px;
  text-align: center;
}

.options {
  border: 1px solid #0f0f0f;
  margin: 10px 20px 0;
}

.option {
  padding: 10px;
  cursor: pointer;
}
