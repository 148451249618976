@import '../../../../core/styles/breakpoints';

.root {
  width: 100%;
  height: 48px;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);

  .content {
    width: 100%;
    height: 48px;
    background: #ffffff;
    text-align: center;
    padding-top: 16px;

    &__box {
      &__time {
        font-family: 'Open Sans Bold';
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #1d1b20;
        padding-right: 16px;
        padding-left: 12px;
      }

      &__change {
        font-family: 'Open Sans';
        font-size: 12px;
        line-height: 16px;
        text-decoration-line: underline;
        color: #027bfd;
      }

      &__icon {
        &__custom {
          display: inline-block;
          position: relative;
          top: 3px;

          svg {
            height: 16px;
            width: 16px;
          }

          color: #3f7bab;
        }
      }
    }
  }
}

.locationHoursRoot {
  padding: 0 16px;
}
