@import '../../core/styles/mixins';

.root {
  color: #777777;
  text-align: right;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 3px 16px 0 16px;
  width: auto;
}

@include bp(lg) {
  .root {
    width: 448px;
    display: flex;
    justify-content: right;
  }
}
